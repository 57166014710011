import React from 'react';

import { Logo } from '../../assets';

import './Footer.css';

export const Footer: React.FC = () => (
    <footer className="Footer">
        <div className="Footer-navigation-container">
            <a href="#header" className="Footer-logo">
                <Logo />
                <span className="Footer-text">Простой способ поднять свои продажи с помощью AI</span>
            </a>
            <nav className="Footer-navigation">
                <a href="#research" className="Footer-link">Исследования</a>
                <a href="#cases" className="Footer-link">Кейсы</a>
                <a href="#why" className="Footer-link">О нас</a>
                <a href="#prices" className="Footer-link">Цены</a>
                <a href="#trial" className="Footer-link">Пробный период</a>
            </nav>
        </div>
        <span className="Footer-text">Copyrights {new Date().getFullYear()}</span>
    </footer>
);
